import {
  BasicTreasury__factory as BasicTreasuryFactory,
  ERC20MintingModule__factory as ERC20MintingModuleFactory,
  NFTMintingModule__factory as NFTMintingModuleFactory,
  ProxyUpgraderModule__factory as ProxyUpgraderModuleFactory
} from '@q-dev/gdk-sdk/lib/ethers-contracts';
import { Contract, providers } from 'ethers';
import { ErrorHandler } from 'helpers';
import { ModuleType } from 'typings/forms';

import { moduleExternalLinks } from 'constants/config';

const IDAO_RESOURCE_INTERFACE = '0x63c8929f';
const ABSTRACT_DEPENDANT_INTERFACE = '0xdb911e84';

const SUPPORTS_INTERFACE_ABI = [
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'interfaceId',
        type: 'bytes4'
      }
    ],
    name: 'supportsInterface',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
];

export async function checkModule (address: string, provider: providers.Provider) {
  try {
    const contract = new Contract(address, SUPPORTS_INTERFACE_ABI, provider);
    const [isSupportedIDAOResource, isSupportedAbstractDependant] = await Promise.all([
      contract.supportsInterface(IDAO_RESOURCE_INTERFACE),
      contract.supportsInterface(ABSTRACT_DEPENDANT_INTERFACE),
    ]);
    return isSupportedIDAOResource && isSupportedAbstractDependant;
  } catch (error) {
    ErrorHandler.processWithoutFeedback(error);
    return false;
  }
}

export function getModuleInitCallData ({
  moduleType,
  tokenAddress,
  expertPanelName,
  moduleName
}: {
  moduleType: ModuleType;
  tokenAddress: string;
  expertPanelName: string;
  moduleName: string;
}) {
  switch (moduleType) {
    case 'nft-minting':
    case 'sbt-minting':
      return NFTMintingModuleFactory.createInterface().encodeFunctionData(
        '__NFTMintingModule_init',
        [
          tokenAddress,
          0,
          `__NFTMintingModule__${moduleName}__${expertPanelName}`,
          expertPanelName,
        ],
      );
    case 'erc20-minting':
      return ERC20MintingModuleFactory.createInterface().encodeFunctionData(
        '__ERC20Module_init',
        [
          tokenAddress,
          `__ERC20MintingModule__${moduleName}__${expertPanelName}`,
          expertPanelName,
        ],
      );
    case 'treasury':
      return BasicTreasuryFactory.createInterface().encodeFunctionData(
        '__BasicTreasury_init',
        [
          `__BasicTreasury__${moduleName}__${expertPanelName}`,
          expertPanelName
        ],
      );
    case 'code-upgrades':
      return ProxyUpgraderModuleFactory.createInterface().encodeFunctionData(
        '__ProxyUpgraderModule_init',
        [
          `__ProxyUpgraderModule__${moduleName}__${expertPanelName}`,
          expertPanelName
        ],
      );
    default:
      return '0x';
  }
}

export function getModuleExternalLink (moduleType: ModuleType) {
  switch (moduleType) {
    case 'nft-minting':
    case 'sbt-minting':
      return moduleExternalLinks.nftMinting;
    case 'erc20-minting':
      return moduleExternalLinks.erc20Minting;
    case 'treasury':
      return moduleExternalLinks.treasury;
    case 'code-upgrades':
      return moduleExternalLinks.proxyUpgrader;
    default:
      return '';
  }
}

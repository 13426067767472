import { VotingType } from '@q-dev/gdk-sdk';
import { utils } from 'ethers';
import {
  createErc20Contract,
  createErc721Contract,
  createStore,
  ErrorHandler,
  fromWei,
  getModuleExternalLink,
  isAddress
} from 'helpers';
import uniqueId from 'lodash/uniqueId';
import {
  DaoCreationForm,
  ExpertPanelFormParameter,
  GeneralMembersVoteFormParameter,
  ModuleForm,
  TokenDetails,
  VetoPowerType,
  VotingTokenType
} from 'typings/forms';

import { TimeVariants } from 'components/TimeTextField/consts';

import { DaoDeployTx, DaoDeployTxTypes } from 'hooks/useDaoDeploy';

import { web3Store } from './web3';

import { chainIdToNetworkMap, NetworkConfig, networkConfigsMap, ORIGIN_NETWORK_NAME } from 'constants/config';
import {
  DAO_PURPOSE_START_TEXT,
  DEFAULT_GUARDIANS_PANEL_NAME,
  DEFAULT_GUARDIANS_PANEL_PURPOSE
} from 'constants/constitution';
import { REPRESENTATIVES_PANEL_NAME } from 'constants/contracts';

type DaoCreationStoreState = {
  version: string;

  form: DaoCreationForm;
};

export const FORM_STORAGE_VERSION = '1.2';

const zeroValues: DaoCreationForm = {
  /* DESCRIBE PURPOSE STEP */
  daoName: '',
  daoPurpose: DAO_PURPOSE_START_TEXT,

  /* VOTING TOKEN STEP */
  tokenFieldType: 'existing-token',
  token: {
    type: 'erc20',
    name: '',
    decimals: '',
    symbol: '',
    totalSupply: '',
    address: '',
  },

  /* EXPERT PANEL STEP */
  expertPanelRoles: [],

  /* REPRESENTATIVES STEP */
  hasRepresentatives: false,

  /* VETO POWERS STEP */
  vetoPowerType: 'nothing',
  guardianExpertPanel: {
    name: DEFAULT_GUARDIANS_PANEL_NAME,
    purpose: DEFAULT_GUARDIANS_PANEL_PURPOSE,

    vetoDetails: {
      configParameterVote: DEFAULT_GUARDIANS_PANEL_NAME,
      generalUpdateVeto: '',
      regularParameterVote: '',
    }
  },
  defaultVetoPanelName: '',

  /* CUSTOMIZE VETO STEP */
  isCustomVeto: false,
  constitutionVeto: '',
  generalUpdateVeto: '',
  membershipVetoes: [],

  /* MODULES STEP */
  modules: [],

  /* TREASURY STEP */
  hasTreasury: false,
  treasuryAddress: '',

  /* CONSTITUTION STEP */
  hasConstitution: false,
  governingLaw: '',
  arbitrationSeat: '',

  deploymentSalt: '',
  isGaslessDeploy: false,

  daoRegistryAddress: '',
  constitutionHash: '',
};

const getDefaultModule = (config: NetworkConfig, moduleIndex = 0): ModuleForm => {
  const { defaultVotingParams } = config;
  return {
    moduleType: 'treasury',

    isModuleAddressValid: true,
    moduleAddress: '',

    isDAOTokenAddress: false,

    isTokenAddressValid: true,
    tokenAddress: '',

    moduleControlPanel: DEFAULT_GUARDIANS_PANEL_NAME,
    moduleName: moduleIndex ? `Treasury ${moduleIndex + 1}` : 'Treasury',
    votingSituationName: moduleIndex ? `Treasury ${moduleIndex + 1} Voting` : 'Treasury Voting',
    appealPanel: '',
    vetoExpertPanel: DEFAULT_GUARDIANS_PANEL_NAME,
    isQRootNodeVeto: false,
    isCustomize: true,
    votingType: String(VotingType.NonRestricted),

    votingPeriod: {
      timeInSec: String(defaultVotingParams.votingPeriod),
      amount: String(defaultVotingParams.votingPeriod),
      time: TimeVariants.Seconds,
    },
    appealPeriod: {
      timeInSec: String(defaultVotingParams.appealPeriod),
      amount: String(defaultVotingParams.appealPeriod),
      time: TimeVariants.Seconds,
    },
    vetoPeriod: {
      timeInSec: String(defaultVotingParams.vetoPeriod),
      amount: String(defaultVotingParams.vetoPeriod),
      time: TimeVariants.Seconds,
    },
    proposalExecutionPeriod: {
      timeInSec: String(defaultVotingParams.proposalExecutionPeriod),
      amount: String(defaultVotingParams.proposalExecutionPeriod),
      time: TimeVariants.Seconds,
    },

    requiredQuorum: fromWei(defaultVotingParams.requiredQuorum, 25),
    requiredMajority: fromWei(defaultVotingParams.requiredMajority, 25),
    requiredVetoQuorum: fromWei(defaultVotingParams.requiredVetoQuorum, 25),
    externalLink: getModuleExternalLink('treasury'),
  };
};

const daoCreationStoreInitialState: DaoCreationStoreState = {
  form: zeroValues,

  version: FORM_STORAGE_VERSION,
};

export const [daoCreationStore, useDaoCreationState] = createStore(
  'dao-creation',
  daoCreationStoreInitialState,
  (state) => ({
    get currentNetworkConfig () {
      const networkName = (
        web3Store.provider?.chainId && chainIdToNetworkMap?.[web3Store.provider?.chainId]
      ) || ORIGIN_NETWORK_NAME;

      return networkConfigsMap[networkName || ORIGIN_NETWORK_NAME];
    },

    get isGuardianExpertPanelExists () {
      if (state.form.vetoPowerType !== 'new-role') return false;

      try {
        return Boolean(state.form.guardianExpertPanel.name && state.form.guardianExpertPanel.purpose?.length);
      } catch (error) {
        return false;
      }
    },

    get MAX_PANELS_COUNT () {
      return 5;
    },

    get representativesPanel (): ExpertPanelFormParameter | undefined {
      if (!state.form.hasRepresentatives) return;

      return {
        name: REPRESENTATIVES_PANEL_NAME,
        purpose: [] as string[],

        vetoDetails: {
          generalUpdateVeto: '',
          regularParameterVote: '',
          configParameterVote: '',
        }
      };
    },
  }),
  (state) => ({
    init () {
      if (state.version !== FORM_STORAGE_VERSION) {
        daoCreationStore.resetDaoCreationStore();
        return;
      }

      state.form.deploymentSalt = utils.id(utils.randomBytes(32).toString());
      state.form.isGaslessDeploy = false;
      state.form.daoRegistryAddress = '';

      if (state.form.tokenFieldType !== 'existing-token') {
        state.form.token.address = '';
      }

      const { qRootNodesVetoParams } = web3Store.getNetworkConfig();

      state.form.modules = state.form.modules.map((module) => {
        if (module.isQRootNodeVeto) {
          return {
            ...module,

            vetoPeriod: {
              time: TimeVariants.Seconds,
              amount: String(qRootNodesVetoParams.vetoPeriod),
              timeInSec: String(qRootNodesVetoParams.vetoPeriod),
            },
            requiredVetoQuorum: fromWei(qRootNodesVetoParams.requiredVetoQuorum, 25),
          };
        }

        return module;
      });
    },

    /* DESCRIBE PURPOSE STEP */
    updateDaoName (name: string) {
      state.form.daoName = name;
    },
    updateDaoPurpose (purpose: string) {
      if (!purpose.startsWith(DAO_PURPOSE_START_TEXT)) {
        state.form.daoPurpose = DAO_PURPOSE_START_TEXT;

        return;
      };

      state.form.daoPurpose = purpose;
    },

    /* VOTING TOKEN STEP */
    updateTokenFieldType (tokenFieldType: VotingTokenType) {
      state.form.tokenFieldType = tokenFieldType;

      const defaultTokenDetails: TokenDetails = {
        'existing-token': {
          type: 'existing-token',
          name: '',
          symbol: '',
          decimals: '',
          totalSupply: '',
          contractURI: '',
          baseURI: '',
          address: '',
        } as TokenDetails,
        erc20: {
          type: 'erc20',
          name: '',
          symbol: '',
          decimals: '18',
          totalSupply: '1000000000',
          contractURI: '',
          baseURI: '',
          address: '',
        } as TokenDetails,
        erc721: {
          type: 'erc721',
          name: '',
          symbol: '',
          decimals: '',
          totalSupply: '1000',
          contractURI: '',
          baseURI: '',
          address: '',
        } as TokenDetails,
        erc5484: {
          type: 'erc5484',
          name: '',
          symbol: '',
          decimals: '',
          totalSupply: '1000',
          contractURI: '',
          baseURI: '',
          address: '',
        } as TokenDetails,
      }[tokenFieldType];

      state.form.token = defaultTokenDetails;
    },
    async updateToken (opts?: {address?: string; tokenDetails?: TokenDetails}) {
      if (!opts) {
        state.form.token = zeroValues.token;
        return;
      }

      if (opts.address && opts.tokenDetails) {
        state.form.token = {
          ...opts.tokenDetails,
          address: opts.address,
        };

        return;
      }

      if (opts.tokenDetails) {
        state.form.token = opts.tokenDetails;
        return;
      }

      state.form.token = {
        ...zeroValues.token,
        address: opts.address,
        type: 'existing-token',
      };
      if (!opts.address || !isAddress(opts.address)) return;

      try {
        if (!web3Store.provider.rawProvider) throw new TypeError('Provider not defined');

        const erc721Contract = createErc721Contract(opts.address, web3Store.provider.rawProvider);

        // double check if address is erc721 contract and upgradable
        const isErc721AndUpgradable = await erc721Contract.safeCheckIsUpgradable();

        if (isErc721AndUpgradable) {
          const erc721Contract = createErc721Contract(opts.address, web3Store.provider.rawProvider);

          const erc721Details = await erc721Contract.loadDetails();

          state.form.token = {
            address: opts.address,
            type: 'erc721',
            name: erc721Details.name,
            symbol: erc721Details.symbol,
            decimals: String(erc721Details.decimals),
            totalSupply: erc721Details.totalSupply,
          };

          return;
        }

        const erc20Contract = createErc20Contract(opts.address, web3Store.provider.rawProvider);

        const erc20Details = await erc20Contract.loadDetails();

        state.form.token = {
          address: opts.address,
          type: 'erc20',
          name: erc20Details.name,
          symbol: erc20Details.symbol,
          decimals: String(erc20Details.decimals),
          totalSupply: erc20Details.totalSupply,
        };
      } catch (error) {
        ErrorHandler.processWithoutFeedback(error);
        state.form.token = {
          ...zeroValues.token,
          address: opts.address,
        };
      }
    },

    /* EXPERT PANEL STEP */
    addExpertPanelRole (value?: ExpertPanelFormParameter) {
      let newExpertPanelRole = value;

      if (!newExpertPanelRole) {
        newExpertPanelRole = {
          name: '',
          purpose: [''],

          vetoDetails: {
            configParameterVote: '',
            generalUpdateVeto: '',
            regularParameterVote: '',
          }
        };
      }

      daoCreationStore.updateExpertPanelRoles([...state.form.expertPanelRoles, newExpertPanelRole]);
    },
    updateExpertPanelRoles (value: ExpertPanelFormParameter[]) {
      state.form.expertPanelRoles = value;
    },

    /* REPRESENTATIVES STEP */
    updateHasRepresentatives (value: boolean) {
      state.form.hasRepresentatives = value;

      daoCreationStore.updateVetoPowerType('nothing');
    },

    /* VETO POWERS STEP */
    updateVetoPowerType (vetoPowerType: VetoPowerType) {
      state.form.vetoPowerType = vetoPowerType;

      state.form.guardianExpertPanel = zeroValues.guardianExpertPanel;

      switch (vetoPowerType) {
        case 'yes':
          daoCreationStore.updateDefaultVetoPanelName(
            state.form.defaultVetoPanelName ||
            state.form.expertPanelRoles[0].name ||
            ''
          );
          break;
        case 'new-role':
          daoCreationStore.updateDefaultVetoPanelName(zeroValues.guardianExpertPanel.name);
          break;
        case 'nothing':
          daoCreationStore.updateDefaultVetoPanelName('');
          break;
      }

      daoCreationStore.updateConstitutionVeto(state.form.defaultVetoPanelName);
      daoCreationStore.updateGeneralUpdateVeto(state.form.defaultVetoPanelName);
    },
    updateGuardianExpertPanel (guardianExpertPanel: ExpertPanelFormParameter) {
      state.form.guardianExpertPanel = guardianExpertPanel;

      daoCreationStore.updateDefaultVetoPanelName(guardianExpertPanel.name);
    },
    updateDefaultVetoPanelName (value: string) {
      state.form.defaultVetoPanelName = value;

      daoCreationStore.updateConstitutionVeto(value);
      daoCreationStore.updateGeneralUpdateVeto(value);

      daoCreationStore.setMembershipVetoesDefault(value);
      daoCreationStore.setUpdateExpertPanelVetoesDefault(value);
    },

    /* CUSTOMIZE VETO STEP */
    updateIsCustomVeto (value: boolean) {
      state.form.isCustomVeto = value;

      if (!value) {
        daoCreationStore.updateVetoPowerType(state.form.vetoPowerType);
      }
    },
    updateConstitutionVeto (value: string) {
      state.form.constitutionVeto = value;
    },
    updateGeneralUpdateVeto (value: string) {
      state.form.generalUpdateVeto = value;
    },
    updateMembershipVetoes (value: GeneralMembersVoteFormParameter[]) {
      state.form.membershipVetoes = value;
    },
    setMembershipVetoesDefault (value = state.form.defaultVetoPanelName) {
      daoCreationStore.updateMembershipVetoes(
        state.form.expertPanelRoles.map(() => ({
          membershipVote: value,
        }))
      );
    },
    // updateExpertPanelVetoes (value: CustomExpertPanelVoteFormParameter[]) {
    //   state.form.expertPanelVetoes = value;
    // },
    setUpdateExpertPanelVetoesDefault (value = state.form.defaultVetoPanelName) {
      /**
       * ExpertPanelsVetoes can't vetoes for themselves by default,
       * but user can select it manually
       */
      state.form.expertPanelRoles = state.form.expertPanelRoles.map((el) => {
        const valueToSet = el.name === value ? '' : value;

        return {
          ...el,
          vetoDetails: {
            configParameterVote: valueToSet,
            regularParameterVote: valueToSet,
            generalUpdateVeto: valueToSet,
          }
        };
      });
    },

    addModule () {
      state.form.modules.push({
        ...getDefaultModule(daoCreationStore.currentNetworkConfig, state.form.modules.length)
      });
    },
    updateModules (modules: ModuleForm[]) {
      state.form.modules = modules;
    },
    removeModule (idx: number) {
      state.form.modules = state.form.modules.filter((_, i) => i !== idx);
    },

    /* TREASURY STEP */
    updateHasTreasury (value: boolean) {
      state.form.hasTreasury = value;
    },
    updateTreasuryAddress (value: string) {
      state.form.treasuryAddress = value;
    },

    /* CONSTITUTION STEP */
    updateHasConstitution (value: boolean) {
      state.form.hasConstitution = value;
    },
    updateGoverningLaw (value: string) {
      state.form.governingLaw = value;
    },
    updateArbitrationSeat (value: string) {
      state.form.arbitrationSeat = value;
    },

    updateDeploymentSalt (value: string) {
      state.form.deploymentSalt = value;
    },
    updateIsGaslessDeploy (value: boolean) {
      state.form.isGaslessDeploy = value;
    },

    setDefaultDeployValues (networkConfig: NetworkConfig) {
      state.form.hasRepresentatives = false;
      state.form.hasTreasury = false;
      state.form.treasuryAddress = '';
      state.form.hasConstitution = false;
      state.form.governingLaw = '';
      state.form.arbitrationSeat = '';
      state.form.vetoPowerType = 'nothing';
      state.form.guardianExpertPanel = zeroValues.guardianExpertPanel;
      state.form.defaultVetoPanelName = '';
      state.form.isCustomVeto = true;
      state.form.membershipVetoes = [{ membershipVote: DEFAULT_GUARDIANS_PANEL_NAME }];
      state.form.constitutionVeto = DEFAULT_GUARDIANS_PANEL_NAME;
      state.form.generalUpdateVeto = DEFAULT_GUARDIANS_PANEL_NAME;
      state.form.expertPanelRoles = [{
        name: DEFAULT_GUARDIANS_PANEL_NAME,
        purpose: DEFAULT_GUARDIANS_PANEL_PURPOSE,

        vetoDetails: {
          configParameterVote: DEFAULT_GUARDIANS_PANEL_NAME,
          regularParameterVote: '',
          generalUpdateVeto: ''
        }
      }];
      state.form.modules = [{ ...getDefaultModule(networkConfig) }];
    },
    revertDefaultDeployValues () {
      state.form.hasRepresentatives = zeroValues.hasRepresentatives;
      state.form.hasTreasury = zeroValues.hasTreasury;
      state.form.treasuryAddress = zeroValues.treasuryAddress;
      state.form.hasConstitution = zeroValues.hasConstitution;
      state.form.governingLaw = zeroValues.governingLaw;
      state.form.arbitrationSeat = zeroValues.arbitrationSeat;
      state.form.vetoPowerType = zeroValues.vetoPowerType;
      state.form.guardianExpertPanel = zeroValues.guardianExpertPanel;
      state.form.defaultVetoPanelName = zeroValues.defaultVetoPanelName;
      state.form.isCustomVeto = zeroValues.isCustomVeto;
      state.form.membershipVetoes = zeroValues.membershipVetoes;
      state.form.constitutionVeto = zeroValues.constitutionVeto;
      state.form.generalUpdateVeto = zeroValues.generalUpdateVeto;
      state.form.expertPanelRoles = zeroValues.expertPanelRoles;
      state.form.modules = zeroValues.modules;
    },

    updateConstitutionHash (value: string) {
      state.form.constitutionHash = value;
    },
    updateDaoRegistryAddress (value: string) {
      state.form.daoRegistryAddress = value;
    },

    resetDaoCreationStore () {
      const daoRegistryAddress = state.form.daoRegistryAddress;
      const constitutionHash = state.form.constitutionHash;

      state.form = {
        ...daoCreationStoreInitialState.form,
        daoRegistryAddress,
        constitutionHash,
      };
    },

    // Important to keep the sequence of transactions!
    getDaoDeployTxsList (): DaoDeployTx[] {
      return [
        ...(!state.form.token.address
          ? [{
            id: uniqueId(),
            txType: 'tokenDeploy' as DaoDeployTxTypes,
            message: {
              translationKey: 'DEPLOY_TOKEN',
              params: {
                name: state.form.token.symbol
              }
            }
          }]
          : []
        ),
        {
          id: uniqueId(),
          txType: 'daoDeploy' as DaoDeployTxTypes,
          message: {
            translationKey: 'DEPLOY_DAO',
          }
        },
        {
          id: uniqueId(),
          txType: 'votingSituationsDeploy',
          message: {
            translationKey: 'CONFIGURE_VOTING_SITUATIONS',
          }
        },
        ...(state.form.hasRepresentatives
          ? [{
            id: uniqueId(),
            txType: 'representativesDeploy' as DaoDeployTxTypes,
            message: {
              translationKey: 'DEPLOY_PANEL',
              params: {
                name: REPRESENTATIVES_PANEL_NAME
              }
            }
          }]
          : []
        ),
        ...state.form.expertPanelRoles.map((item, index) => {
          return {
            id: uniqueId(),
            txType: 'expertPanelDeploy' as DaoDeployTxTypes,
            expertPanelIndex: index,
            message: {
              translationKey: 'DEPLOY_PANEL',
              params: {
                name: item.name
              }
            }
          };
        }),
        ...(state.form.vetoPowerType === 'new-role' && state.form.guardianExpertPanel.name
          ? [{
            id: uniqueId(),
            txType: 'guardianExpertPanelDeploy' as DaoDeployTxTypes,
            message: {
              translationKey: 'DEPLOY_PANEL',
              params: {
                name: state.form.guardianExpertPanel.name
              }
            }
          }]
          : []
        ),
        ...state.form.modules.map((item, index) => {
          return {
            id: uniqueId(),
            txType: 'panelModuleDeploy' as DaoDeployTxTypes,
            panelModuleIndex: index,
            message: {
              translationKey: 'DEPLOY_PANEL_MODULE',
              params: {
                name: item.moduleName
              }
            }
          };
        }),
        {
          id: uniqueId(),
          txType: 'vetoParamsDeploy',
          message: {
            translationKey: 'DEPLOY_VETO_PARAMS'
          }
        },
        // `revokeDAOCreatorRole` should always be last
        {
          id: uniqueId(),
          txType: 'revokeDAOCreatorRole',
          message: {
            translationKey: 'REVOKE_DAO_CREATOR_ACCESS'
          }
        },
      ];
    },

    cancelDeploy () {
      daoCreationStore.init();
    },
  }),
);
